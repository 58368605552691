import { Link } from "react-router-dom";

function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer mt-3 bg-light text-dark">
      <div className="container py-3">
        <div className="row align-items-center">
          {/* Left section for links */}
          <div className="col-md-6 col-12 text-center text-md-start mb-3 mb-md-0">
            <ul className="list-unstyled d-flex flex-column flex-md-row gap-3 justify-content-center justify-content-md-start mb-0">
              <li>
                <Link to="/about" className="text-decoration-none text-dark">من نحن</Link>
              </li>
              <li>
                <Link to="/terms-of-service" className="text-decoration-none text-dark">شروط الخدمة</Link>
              </li>
              <li>
                <Link to="/privacy-policy" className="text-decoration-none text-dark">سياسة الخصوصية</Link>
              </li>
              <li>
                <Link to="/contact" className="text-decoration-none text-dark">اتصل بنا</Link>
              </li>
            </ul>
          </div>

          {/* Right section for social media icons */}
          <div className="col-md-6 col-12 text-center text-md-end">
            <a href="https://www.instagram.com/soumar.techpark/" target="_blank" rel="noopener noreferrer" className="text-dark mx-2" aria-label="Instagram">
              <i className="bi bi-instagram fs-5"></i>
            </a>
            <a href="https://x.com/STechpark62878" target="_blank" rel="noopener noreferrer" className="text-dark mx-2" aria-label="Twitter">
              <i className="bi bi-twitter-x fs-5"></i>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;