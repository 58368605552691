import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Container, Row, Col, Image, Button, Dropdown } from 'react-bootstrap';
import { firestore } from './Authentication/firebase';
import { collection, getDocs, query, where, doc, getDoc } from 'firebase/firestore';
import Disqus from 'disqus-react';
import { useContext } from 'react';
import { DarkModeContext } from './DarkModeContext';
import { Helmet } from 'react-helmet-async';
import { BsTwitterX, BsFacebook, BsLink45Deg } from 'react-icons/bs';
import { FiShare } from 'react-icons/fi';

const ArticlePage = ({ setIsLoading }) => {
    const { darkMode } = useContext(DarkModeContext);
    const { slug } = useParams();
    const [article, setArticle] = useState(null);
    const [user, setUser] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);

        const fetchArticle = async () => {
            setIsLoading(true);

            try {
                const articlesRef = collection(firestore, 'articles');
                const q = query(articlesRef, where('slug', '==', slug));
                const querySnapshot = await getDocs(q);

                if (!querySnapshot.empty) {
                    const docSnap = querySnapshot.docs[0];
                    const articleData = { id: docSnap.id, ...docSnap.data() };
                    setArticle(articleData);

                    const userRef = doc(firestore, 'users', articleData.userId);
                    const userSnap = await getDoc(userRef);

                    if (userSnap.exists()) {
                        setUser(userSnap.data());
                    } else {
                        throw new Error('User not found');
                    }
                } else {
                    throw new Error('Article not found');
                }
            } catch (err) {
                setError(err.message);
                console.error('Error fetching article:', err);
            } finally {
                setIsLoading(false);
            }
        };

        fetchArticle();
    }, [slug]);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return new Intl.DateTimeFormat('ar-EG', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
            numberingSystem: 'latn'
        }).format(date);
    };

    const disqusShortname = "soumarTechPark";
    const disqusConfig = {
        identifier: article?.id,
        title: article?.title,
        theme: darkMode ? "dark" : "light"
    };

    if (error) {
        return <p className="text-danger text-center">{error}</p>;
    }

    const currentUrl = window.location.href;

    const copyToClipboard = () => {
        navigator.clipboard.writeText(currentUrl);
        alert("تم نسخ الرابط!"); // يمكنك استبدال alert بإشعار أكثر احترافية
    };

    return (
        <Container>
            {article &&
                <Helmet>
                    <title>{article.title}</title>
                    <meta name="description" content={article.intro} data-react-helmet="true" />
                    <meta property="og:title" content={article.title} data-react-helmet="true" />
                    <meta property="og:description" content={article.intro} data-react-helmet="true" />
                    <meta property="og:image" content={article.imageUrl} data-react-helmet="true" />
                    <meta property="og:url" content={currentUrl} data-react-helmet="true" />
                    <meta property="fb:app_id" content="1353464168997238" data-react-helmet="true" />
                    <meta name="twitter:card" content="summary_large_image" data-react-helmet="true" />
                    <meta name="twitter:title" content={article.title} data-react-helmet="true" />
                    <meta name="twitter:description" content={article.intro} data-react-helmet="true" />
                    <meta name="twitter:image" content={article.imageUrl} data-react-helmet="true" />
                    <meta name="twitter:image:alt" content={article.title} data-react-helmet="true" />
                </Helmet>}
            {article &&
                <Row>
                    <Col md="8">
 

                        <h1 className="mb-2 text-bold">{article.title}</h1>
                        <p className="text-muted intro">{article.intro}</p>
                        <div className='text-center'>
                            {article.imageUrl && (
                                <Image
                                    src={article.imageUrl}
                                    alt="Article cover"
                                    className="img-fluid rounded mb-4"
                                    loading="eager"
                                />
                            )}
                        </div>

                        {user && (
                        <div style={{ padding: '10px' }}>
                            <Row className="align-items-center">
                                <Col>
                                    <div>
                                        {article.isShowWriter && 
                                            <h5>
                                                الكاتب <Link to={`/profile/${user.username}`} className="text-decoration-none">
                                                    {user.firstName} {user.lastName}
                                                </Link>
                                            </h5>
                                        }
                                        <div className="date">{formatDate(article.createdAt)}</div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    )}

                       <Dropdown className="mb-3">
                            <Dropdown.Toggle variant="dark" className="rounded-circle d-flex align-items-center justify-content-center p-3">
                                <FiShare />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item
                                    href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(currentUrl)}&text=${encodeURIComponent(article.title)}`}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <BsTwitterX /> شارك المقال على اكس
                                </Dropdown.Item>
                                <Dropdown.Item
                                    href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(currentUrl)}`}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <BsFacebook /> شارك المقال على فيسبوك
                                </Dropdown.Item>
                                <Dropdown.Item onClick={copyToClipboard}>
                                    <BsLink45Deg /> نسخ الرابط
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>

                    <div
                        className="article-content"
                        dangerouslySetInnerHTML={{ __html: article.content }}
                    />

                    {article.category === "Reviews" ?
                        <div className='score-card'>
                            <div className="p-3 score">
                                {article.score}
                            </div>
                            <div className="comment mt-3"><h2><b>{article.comment}</b></h2>
                                <div className='comment-details'>
                                    {article.commentDetails}
                                </div>
                            </div>
                        </div> : ""}

                        {article?.tags && article.tags.length > 0 && (
                            <div>
                                <h5 className="text-muted">الوسوم:</h5>
                                <div className="d-flex flex-wrap">
                                    {article.tags.map((tag, index) => (
                                        <Link 
                                            key={index} 
                                            to={`/tag/${tag}`} 
                                            className="badge bg-secondary text-white me-2 text-decoration-none"
                                            style={{ padding: '8px 12px', borderRadius: '20px' }}
                                        >
                                            #{tag}
                                        </Link>
                                    ))}
                                </div>
                            </div>
                        )}

                        <div>
                            <Disqus.DiscussionEmbed
                                shortname={disqusShortname}
                                config={disqusConfig}
                            />
                        </div>
                    </Col>
                </Row>}
        </Container>
    );
};

export default ArticlePage;