import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { firestore } from './Authentication/firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';

const SearchResults = ({setIsLoading}) => {
    const [articles, setArticles] = useState([]);
    const location = useLocation();
    const searchQuery = new URLSearchParams(location.search).get('q');

    useEffect(() => {
        setIsLoading(true);
        const fetchSearchResults = async () => {
            if (!searchQuery) return;

            try {
                const articlesRef = collection(firestore, 'articles');
                const q = query(articlesRef, where('title', '>=', searchQuery), where('title', '<=', searchQuery + '\uf8ff'));
                const querySnapshot = await getDocs(q);
                
                setArticles(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
            } catch (error) {
                console.error("Error fetching search results:", error);
            } finally { 
                setIsLoading(false);
            }
        };

        fetchSearchResults();
    }, [searchQuery]);

    return (
        <Container>
            <h1 className="mt-4">نتائج البحث عن: "{searchQuery}"</h1>
            <Row>
                {articles.length > 0 ? (
                    articles.map(article => (
                        <Col key={article.id} md={4}>
                            <Card className="mb-4">
                                <Card.Img variant="top" src={article.imageUrl} alt="Article image" />
                                <Card.Body>
                                    <Card.Title>{article.title}</Card.Title>
                                    <Card.Text>{article.intro}</Card.Text>
                                    <Link to={`/articles/${article.slug}`} className="btn btn-primary">
                                        قراءة المزيد
                                    </Link>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))
                ) : (
                    <p>لا توجد نتائج مطابقة.</p>
                )}
            </Row>
        </Container>
    );
};

export default SearchResults;
