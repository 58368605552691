import { Container, Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { useAuth } from "./Authentication/AuthProvider"; 
import { signOut } from "firebase/auth";
import { auth, firestore } from "./Authentication/firebase"; 
import { useNavigate, Link } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import { doc, getDoc } from "firebase/firestore";
import { FaUser } from 'react-icons/fa';
import { FaMessage } from 'react-icons/fa6';

const AppNavbar = ({ darkMode }) => {
  const [userInfo, setUserInfo] = useState(null); 
  const { currentUser } = useAuth(); 
  const navigate = useNavigate();
  const [activeLink, setActiveLink] = useState("/"); // Default active link
  const [expanded, setExpanded] = useState(false); // Track navbar open state

  useEffect(() => {
    const fetchUserInfo = async () => {
      if (currentUser) {
        const userRef = doc(firestore, "users", currentUser.uid);
        const userSnap = await getDoc(userRef);

        if (userSnap.exists()) {
          setUserInfo(userSnap.data()); 
        } else {
          console.error("User info not found");
        }
      }
    };

    fetchUserInfo();
  }, [currentUser]);

  // Handle logout
  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        navigate("/");
      })
      .catch((error) => {
        console.error("Error signing out:", error);
      });
  };

  // Function to set active link & close navbar
  const handleLinkClick = (link) => {
    setActiveLink(link);
    setExpanded(false); // Close navbar after clicking a link
  };

  return (
    <Navbar 
      className="sticky-top" 
      variant={darkMode ? "dark" : "light"} 
      bg={darkMode ? "dark" : "light"} 
      expand="lg"
      expanded={expanded} // Control the expanded state
    >
      <Container>
        <Navbar.Toggle 
          aria-controls="basic-navbar-nav"
          onClick={() => setExpanded(!expanded)} // Toggle navbar
        />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link
              as={Link}
              to="/"
              className={`nav-link ${activeLink === "/" ? "active" : ""}`}
              onClick={() => handleLinkClick("/")}
            >
              الرئيسية
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/news"
              className={`nav-link ${activeLink === "/news" ? "active" : ""}`}
              onClick={() => handleLinkClick("/news")}
            >
              أخبار
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/reports"
              className={`nav-link ${activeLink === "/reports" ? "active" : ""}`}
              onClick={() => handleLinkClick("/reports")}
            >
              تقارير
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/reviews"
              className={`nav-link ${activeLink === "/reviews" ? "active" : ""}`}
              onClick={() => handleLinkClick("/reviews")}
            >
              مراجعات
            </Nav.Link>

            {currentUser && (
              <>

                <Nav.Link
                  as={Link}
                  to="/add"
                  className={`nav-link ${activeLink === "/add" ? "active" : ""}`}
                  onClick={() => handleLinkClick("/add")}
                >
                  إضافة مقال
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/messages"
                  className={`nav-link ${activeLink === "/messages" ? "active" : ""}`}
                  onClick={() => handleLinkClick("/messages")}
                >
                  <FaMessage />
                </Nav.Link>
              </>
            )}

            {currentUser && userInfo ? (
              <NavDropdown 
                title={<FaUser />} 
                id="profile-dropdown" 
                align="end"
              >
                <NavDropdown.Item 
                  as={Link} 
                  to={`/profile/${userInfo.username}`}
                  onClick={() => handleLinkClick(`/profile/${userInfo.username}`)}
                >
                  تعديل الملف الشخصي
                </NavDropdown.Item>
                <NavDropdown.Item 
                  as={Link} 
                  to="/settings"
                  onClick={() => handleLinkClick("/settings")}
                >
                  الإعدادات
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={handleLogout}>تسجيل الخروج</NavDropdown.Item>
              </NavDropdown>
            ) : (
              <>
                {/* Add login/signup links if needed */}
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default AppNavbar;