import React, { useState } from 'react';
import { Container, Navbar, Image, Button, Nav, Form, InputGroup, Modal } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { BsSearch, BsTwitterX } from 'react-icons/bs';
import { FiSun, FiMoon } from 'react-icons/fi';

function UpperNavbar({ darkMode, toggleDarkMode }) {
  const [searchQuery, setSearchQuery] = useState('');
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchQuery.trim()) {
      navigate(`/search?q=${searchQuery}`);
      setSearchQuery('');
      setShowModal(false);
    }
  };

  return (
    <>
      <Navbar className={`py-2 shadow-sm ${darkMode ? 'bg-dark text-light' : 'bg-white text-dark'}`}>
        <Container className="d-flex justify-content-between align-items-center">
          
          {/* Logo */}
          <Navbar.Brand as={Link} to="/">
            <Image src="../logo.png" width="50" height="50" className="rounded-circle" alt='site-logo'/>
          </Navbar.Brand>

          {/* Icons */}
          <Nav className="d-flex align-items-center">
            <Nav.Item>
              <Button
                aria-label="Search"
                variant="link"
                className={`navbar-icon ${darkMode ? 'text-light' : 'text-dark'}`}
                onClick={() => setShowModal(true)}>
                <BsSearch size={20} />
              </Button>
            </Nav.Item>
            {/* Dark Mode Toggle */}
            <Nav.Item>
              <Button
                as="button"
                variant="link"
                onClick={toggleDarkMode}
                className={`navbar-icon ${darkMode ? 'text-light' : 'text-dark'}`}
                aria-label="Toggle dark mode"
              >
                {darkMode ? <FiSun size={20} /> : <FiMoon size={20} />}
              </Button>
            </Nav.Item>

            {/* Twitter Link */}
            <Nav.Item>
              <Button
                as="a"
                href="https://x.com/STechpark62878"
                target="_blank"
                aria-label="Twitter"
                rel="noopener noreferrer"
                variant="link"
                className={`navbar-icon ${darkMode ? 'text-light' : 'text-dark'}`}
              >
                <BsTwitterX size={20} />
              </Button>
            </Nav.Item>
          </Nav>
        </Container>
      </Navbar>

      {/* Search Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title className="w-100 text-center">بحث</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSearch}>
            <InputGroup>
              <Button 
                type="submit"
                variant="outline-secondary"
                className="rounded-end-pill">
                <BsSearch />
              </Button>
              <Form.Control
                type="text"
                placeholder="ابحث..."
                 style={{ 
                  backgroundColor: darkMode ? '#1e1e1e' : '#f8f9fa', 
                  color: darkMode ? '#fff' : '#000', 
                  border: darkMode ? '1px solid #555' : '1px solid #ccc', 
                  borderRadius: '0'
                }}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </InputGroup>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default UpperNavbar;