import React, { useEffect, useState } from 'react';
import { Row, Col, Image, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { firestore } from './Authentication/firebase';
import { collection, getDocs, query, orderBy, where, limit, startAfter, doc, getDoc } from 'firebase/firestore';
import FeaturedCarousel from './FeaturedCarousel';
import { useAuth } from "./Authentication/AuthProvider";

const HomePage = ({ isLoading, setIsLoading }) => {
    const [blogPosts, setBlogPosts] = useState([]);
    const [featuredPosts, setFeaturedPosts] = useState([]);
    const [error, setError] = useState(null);
    const [lastVisible, setLastVisible] = useState(null);
    const [hasMore, setHasMore] = useState(true);
    const [userInfo, setUserInfo] = useState(null);
    const { currentUser } = useAuth();
    const batchSize = 6;

    useEffect(() => {
        fetchPosts(); 
        fetchFeaturedPosts();
    }, []);

    useEffect(() => {
        const fetchUserInfo = async () => {
            if (currentUser) {
                const userRef = doc(firestore, "users", currentUser.uid);
                const userSnap = await getDoc(userRef);

                if (userSnap.exists()) {
                    setUserInfo(userSnap.data());
                } else {
                    console.error("User info not found");
                }
            }
        };

        fetchUserInfo();
    }, [currentUser]);

    const fetchPosts = async (next = false) => {
        setIsLoading(true);
        try {
            const postsRef = collection(firestore, 'articles');
            let q = query(postsRef, orderBy('createdAt', 'desc'), limit(batchSize));

            if (next && lastVisible) {
                q = query(postsRef, orderBy('createdAt', 'desc'), startAfter(lastVisible), limit(batchSize));
            }

            const querySnapshot = await getDocs(q);
            const posts = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }));

            setBlogPosts((prevPosts) => (next ? [...prevPosts, ...posts] : posts));
            setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
            setHasMore(querySnapshot.docs.length === batchSize);
        } catch (err) {
            setError('Failed to fetch posts from Firestore: ' + err.message);
            console.error('Error fetching posts:', err);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchFeaturedPosts = async () => {
        try {
            const postsRef = collection(firestore, 'articles');
            const q = query(postsRef, where('featured', '==', true), limit(5));
            const querySnapshot = await getDocs(q);
            const posts = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }));
            setFeaturedPosts(posts);
        } catch (err) {
            setError('Failed to fetch featured posts: ' + err.message);
            console.error('Error fetching featured posts:', err);
        }
    };

    // ✅ Function to get optimized Firebase Storage URL
    const getOptimizedFirebaseUrl = (imageUrl, width = 400, height = 250) => {
        return `${imageUrl}=w${width}-h${height}-c`;
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return new Intl.DateTimeFormat('ar-EG', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
            numberingSystem: 'latn'
        }).format(date);
    };

    return (
        <div>
            {error && <p className="text-danger text-center">{error}</p>}
            <FeaturedCarousel featuredPosts={featuredPosts} />
            <Row className="mt-5">
                <Col></Col>
                <Col md="8">
                    <h3 className="font-weight-bold">آخر المقالات</h3>
                    {blogPosts.map((post) => (
                        <Col md={12} key={post.id} className="mb-3">
                            <div className="d-flex flex-column flex-md-row home-article-border-bottom">
                                <div className="p-2 text-center text-md-start">
                                    <Link to={`/articles/${post.slug}`}>
                                        {/* ✅ Use optimized image URL */}
                                        <Image 
                                            src={getOptimizedFirebaseUrl(post.imageUrl, 400, 250)} 
                                            className="note-image" 
                                            alt="Article image"
                                            loading="lazy" 
                                        />
                                    </Link>
                                </div>
                                <div className="p-2">
                                    <div className="category mb-1">
                                        {post.category === 'Reports' ? (
                                            <Link to="/reports">تقارير</Link>
                                        ) : post.category === 'Reviews' ? (
                                            <Link to="/reviews">مراجعات</Link>
                                        ) : post.category === 'News' ? (
                                            <Link to="/news">أخبار</Link>
                                        ) : (
                                            post.category
                                        )}
                                    </div>
                                    <div className="mb-1">
                                        <Link to={`/articles/${post.slug}`} className="post-title">
                                            {post.title}
                                        </Link>
                                    </div>
                                    <div className="mb-1 date">
                                        {formatDate(post.createdAt)}
                                    </div>
                                    {currentUser && (
                                        <div className="mt-2">
                                            <Link to={`/edit/${post.slug}`}>
                                                <Button variant="warning" size="sm">تعديل المقال</Button>
                                            </Link>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Col>
                    ))}

                    {!isLoading && (
                        <div className="text-center mt-4">
                            {hasMore ? (
                                <Button onClick={() => fetchPosts(true)} size="sm" variant="secondary">
                                    شاهد المزيد
                                </Button>
                            ) : (
                                <p>لا يوجد المزيد من المقالات</p>
                            )}
                        </div>
                    )}
                </Col>
                <Col></Col>
            </Row>
        </div>
    );
};

export default HomePage;
