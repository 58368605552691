import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { firestore } from './Authentication/firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { Helmet } from 'react-helmet-async';

const TagPage = () => {
    const { tag } = useParams();
    const [articles, setArticles] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);
        const fetchArticlesByTag = async () => {
            try {
                const articlesRef = collection(firestore, 'articles');
                const q = query(articlesRef, where('tags', 'array-contains', tag));
                const querySnapshot = await getDocs(q);

                const articlesList = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));

                setArticles(articlesList);
            } catch (error) {
                console.error("Error fetching articles:", error);
            }
        };

        fetchArticlesByTag();
    }, [tag]);

    return (
        <Container>

        <Helmet>
            <title>المقالات تحت وسم: {tag}</title>
            <meta name="description" content={`مقالات حول ${tag}`} />
            <meta property="og:title" content={`المقالات تحت وسم: ${tag}`} />
            <meta property="og:description" content={`استكشف المقالات حول ${tag}`} />
        </Helmet>

            <h1 className="mt-4">المقالات تحت وسم: {tag}</h1>
            <Row>
                {articles.map(article => (
                    <Col key={article.id} md={4}>
                        <Card className="mb-4">
                            <Card.Img variant="top" src={article.imageUrl} alt="Article Image" />
                            <Card.Body>
                                <Card.Title>{article.title}</Card.Title>
                                <Card.Text>{article.intro}</Card.Text>
                                <Link to={`/articles/${article.slug}`} className="btn btn-primary">
                                    قراءة المزيد
                                </Link>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Container>
    );
};

export default TagPage;