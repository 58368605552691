import React, { useEffect, useState } from 'react';
import { Row, Col, Image, Button, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { firestore } from './Authentication/firebase';
import { collection, getDocs, query, orderBy, limit, startAfter, where, doc, getDoc } from 'firebase/firestore';
import { useAuth } from "./Authentication/AuthProvider";

const CategoryPage = ({ category, isLoading, setIsLoading }) => {
    const [articles, setArticles] = useState([]);
    const [lastVisible, setLastVisible] = useState(null);
    const [error, setError] = useState(null);
    const [hasMore, setHasMore] = useState(true);
    const batchSize = 7;    
    const { currentUser } = useAuth(); 
    const [userInfo, setUserInfo] = useState(null);
    
    const fetchArticles = async (next = false) => {
        setIsLoading(true);
        try {
            const articlesRef = collection(firestore, 'articles');
            let q = query(
                articlesRef,
                where('category', '==', category),
                orderBy('createdAt', 'desc'),
                limit(batchSize)
            );

            if (next && lastVisible) {
                q = query(articlesRef, where('category', '==', category), orderBy('createdAt', 'desc'), startAfter(lastVisible), limit(batchSize));
            }

            const querySnapshot = await getDocs(q);
            const articlesList = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }));

            setHasMore(querySnapshot.docs.length === batchSize);
            setArticles((prev) => (next ? [...prev, ...articlesList] : articlesList));
            if (querySnapshot.docs.length > 0) {
                setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
            }
        } catch (err) {
            setError('Failed to fetch articles from Firestore: ' + err.message);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchArticles();
    }, [category]);

    useEffect(() => {
        const fetchUserInfo = async () => {
            if (currentUser) {
                const userRef = doc(firestore, "users", currentUser.uid);
                const userSnap = await getDoc(userRef);

                if (userSnap.exists()) {
                    setUserInfo(userSnap.data());
                } else {
                    console.error("User info not found");
                }
            }
        };

        fetchUserInfo();
    }, [currentUser]);

    const categoryMap = {
        News: "أخبار",
        Reports: "تقارير",
        Reviews: "مراجعات"
    };

    const getCategory = (category) => categoryMap[category] || category;

    return (
        <div>
            {error && <p className="text-danger text-center">{error}</p>}
            <Row className="mt-5">
                <Col></Col>
                <Col md="8">
                    <h1><b>{getCategory(category)}</b></h1>
                    {category === 'Reviews' ? (
                        <Row>
                            {articles.map((post) => (
                                <Col md={4} key={post.id} className='mb-3'>
                                    <Link to={`/articles/${post.slug}`} className="text-decoration-none">
                                        <Card className="text-white">
                                            <Card.Img src={post.imageUrl} alt={post.title} />
                                            <Card.ImgOverlay className="d-flex align-items-center justify-content-center bg-dark bg-opacity-50">
                                                <Card.Title className="text-center">{post.title}</Card.Title>
                                            </Card.ImgOverlay>
                                        </Card>
                                    </Link>
                                </Col>
                            ))}
                        </Row>
                    ) : (
                        articles.map((post) => (
                            <Col md={12} key={post.id} className='mb-3'>
                                <div className="d-flex flex-column flex-md-row home-article-border-bottom">
                                    <div className="p-2 text-center text-md-start">
                                        <Link to={`/articles/${post.slug}`}>
                                            <Image 
                                                src={post.imageUrl} 
                                                className="note-image" 
                                                alt="Article image"
                                            />
                                        </Link>
                                    </div>
                                    <div className="p-2">
                                        <div className="mb-1">
                                            <Link to={`/articles/${post.slug}`} className="post-title">
                                                {post.title}
                                            </Link>
                                        </div>
                                        {currentUser && (
                                            <div className="mt-2">
                                                <Link to={`/edit/${post.slug}`}>
                                                    <Button aria-label="Edit Article" variant="warning" size="sm">تعديل المقال</Button>
                                                </Link>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </Col>
                        ))
                    )}
                    {!isLoading && (
                    <div className="text-center mt-4">
                        {hasMore ? (
                        <Button aria-label="Load more" onClick={() => fetchArticles(true)} size="sm" variant="secondary">
                            شاهد المزيد
                        </Button>
                        ) : (
                        <p>لا يوجد المزيد من المقالات</p>
                        )}
                    </div>
                    )}
                </Col>
                <Col></Col>
            </Row>
        </div>
    );
};

export default CategoryPage;
