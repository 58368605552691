import React, { useState } from 'react';
import { Container, Form, Button, Alert, Row, Col, Spinner, Badge } from 'react-bootstrap';
import { collection, addDoc } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { firestore, storage, auth } from './Authentication/firebase';
import ReactQuill from 'react-quill-new';
import { useNavigate } from 'react-router-dom';

const AddArticle = () => {
    const modules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['link', 'image'],        ],
    };

    const formats = [
        'bold',
        'italic',
        'underline',
        'strike',
        'link',
        'image',
    ];

    const [title, setTitle] = useState('');
    const [intro, setIntro] = useState('');
    const [tags, setTags] = useState([]);
    const [tagInput, setTagInput] = useState('');
    const [content, setContent] = useState('');
    const [category, setCategory] = useState('');
    const [image, setImage] = useState(null);
    const [featured, setFeatured] = useState(false);
    const [isShowWriter, setIsShowWriter] = useState(false);
    const [comment, setComment] = useState('');
    const [commentDetails, setCommentDetails] = useState('');
    const [score, setScore] = useState('');
    const [createdAt, setCreatedAt] = useState(new Date().toISOString().split('T')[0]);
    const [error, setError] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [message, setMessage] = useState(null);

    const navigate = useNavigate();
    
    const generateSlug = (title) => {
        return title
            .toLowerCase()
            .trim()
            .replace(/[^ء-يa-zA-Z0-9\s-]/g, '')
            .replace(/\s+/g, '-')
            .replace(/-+/g, '-');
    };

    const handleTagKeyDown = (e) => {
        if (e.key === 'Enter' || e.key === ',') {
            e.preventDefault();
            const newTag = tagInput.trim();
            if (newTag && !tags.includes(newTag)) {
                setTags([...tags, newTag]);
            }
            setTagInput('');
        }
    };

    const removeTag = (tagToRemove) => {
        setTags(tags.filter(tag => tag !== tagToRemove));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);
        setUploading(true);
        setMessage(null);

        // Validation
        if (!title.trim()) {
            setError('العنوان مطلوب.');
            setUploading(false);
            return;
        }
        if (!intro.trim()) {
            setError('المقدمة مطلوبة.');
            setUploading(false);
            return;
        }
        if (!content.trim()) {
            setError('المحتوى مطلوب.');
            setUploading(false);
            return;
        }
        if (!category) {
            setError('التصنيف مطلوب.');
            setUploading(false);
            return;
        }
        if (!image) {
            setError('الصورة مطلوبة.');
            setUploading(false);
            return;
        }
        if (category === 'Reviews' && (!score || score < 0 || score > 10)) {
            setError('يجب أن يكون التقييم بين 0 و 10 للمراجعات.');
            setUploading(false);
            return;
        }

        try {
            const user = auth.currentUser;
            if (!user) {
                setError('يجب أن تكون مسجلاً للدخول لإضافة مقال.');
                setUploading(false);
                return;
            }

            const slug = generateSlug(title);
            const imageRef = ref(storage, `images/${image.name}`);
            const uploadTask = uploadBytesResumable(imageRef, image);

            uploadTask.on(
                'state_changed',
                null,
                (err) => {
                    setError(err.message);
                    setUploading(false);
                },
                async () => {
                    const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

                    await addDoc(collection(firestore, 'articles'), {
                        title,
                        slug,
                        intro,
                        content,
                        comment,
                        commentDetails,
                        category,
                        imageUrl: downloadURL,
                        isShowWriter,
                        featured,
                        tags,
                        createdAt: new Date(createdAt).toISOString(),
                        userId: user.uid,
                        ...(category === 'Reviews' && { score }),
                    });

                    setUploading(false);
                    setMessage('تم إضافة المقال بنجاح!');
                    navigate(`/`);
                }
            );
        } catch (err) {
            setError('فشل في إضافة المقال: ' + err.message);
            setUploading(false);
        }
    };

    const handleImageChange = (e) => {
        if (e.target.files.length > 0) {
            setImage(e.target.files[0]);
        }
    };

    return (
        <Container >
            <h1 className="my-4 text-center">إضافة مقال جديد</h1>
            {error && <Alert variant="danger">{error}</Alert>}
            {message && <Alert variant="success">{message}</Alert>}

            <Form onSubmit={handleSubmit}>
                <Row>
                    <Col>
                        <Form.Group controlId="formTitle">
                            <Form.Label>العنوان *</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="اكتب العنوان هنا..."
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Row className='mt-3'>
                    <Col>
                        <Form.Group controlId="formIntro">
                            <Form.Label>المقدمة *</Form.Label>
                            <Form.Control
                                as="textarea"
                                placeholder="اكتب المقدمة هنا..."
                                value={intro}
                                onChange={(e) => setIntro(e.target.value)}
                                style={{ height: '100px' }}
                                />
                        </Form.Group>
                    </Col>
                </Row>

                <Row className='mt-3'>
                    <Col md={6}>
                        <Form.Group controlId="formCategory">
                            <Form.Label>تصنيف *</Form.Label>
                            <Form.Select
                                value={category}
                                onChange={(e) => setCategory(e.target.value)}
                            >
                                <option value="">اختيار تصنيف</option>
                                <option value="News">أخبار</option>
                                <option value="Reports">تقارير</option>
                                <option value="Reviews">مراجعات</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group controlId="formCreatedAt">
                            <Form.Label>تاريخ الإنشاء</Form.Label>
                            <Form.Control
                                type="date"
                                value={createdAt}
                                onChange={(e) => setCreatedAt(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                </Row>

                {category === 'Reviews' && (
                    <>
                        <Form.Group controlId="formComment" className="mt-3">
                            <Form.Label>عنوان التعليق</Form.Label>
                            <Form.Control
                                type="text"
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group controlId="formCommentDetails" className="mt-3">
                            <Form.Label>التعليق</Form.Label>
                            <Form.Control
                                type="text"
                                value={commentDetails}
                                onChange={(e) => setCommentDetails(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group controlId="formScore" className="mt-3">
                            <Form.Label>التقييم (0-10)</Form.Label>
                            <Form.Control
                                type="number"
                                min="0"
                                max="10"
                                value={score}
                                onChange={(e) => setScore(e.target.value)}
                            />
                        </Form.Group>
                    </>
                )}

                <Form.Group controlId="formTags" className="mt-3">
                    <Form.Label>الوسوم (اضغط Enter بعد كل وسم)</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="اكتب الوسوم هنا..."
                        value={tagInput}
                        onChange={(e) => setTagInput(e.target.value)}
                        onKeyDown={handleTagKeyDown}
                    />
                    <div className="mt-2">
                        {tags.map((tag, index) => (
                            <Badge key={index} bg="secondary" className="me-2">
                                {tag} <span onClick={() => removeTag(tag)} style={{ cursor: 'pointer' }}>×</span>
                            </Badge>
                        ))}
                    </div>
                </Form.Group>

                <Form.Group controlId="formContent" className="mt-3">
                    <Form.Label>المحتوى *</Form.Label>
                    <ReactQuill
                        value={content}
                        onChange={setContent}
                        modules={modules}
                        formats={formats}
                        theme="snow"
                        placeholder="اكتب مقالك هنا..."
                    />
                </Form.Group>

                <Form.Group controlId="formImage" className="mt-3">
                    <Form.Label>تحميل صورة المقال *</Form.Label>
                    <Form.Control
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                    />
                </Form.Group>

                <Form.Group controlId="formFeatured" className="mt-3">
                    <Form.Check
                        type="checkbox"
                        label="تمييز المقال"
                        checked={featured}
                        onChange={(e) => setFeatured(e.target.checked)}
                    />
                </Form.Group>

                <Form.Group controlId="formIsShowWriter" className="mt-3">
                    <Form.Check
                        type="checkbox"
                        label="إظهار اسم الكاتب"
                        checked={isShowWriter}
                        onChange={(e) => setIsShowWriter(e.target.checked)}
                    />
                </Form.Group>

                <Button aria-label="Add Article" variant="primary" type="submit" className="mt-4" disabled={uploading}>
                    {uploading ? (
                        <>
                            <Spinner className="custom-spinner" animation="grow" size="sm" /> يتم التحميل...
                        </>
                    ) : (
                        'إضافة المقال'
                    )}
                </Button>
            </Form>
        </Container>
    );
};

export default AddArticle;