import { useState, useEffect } from 'react';
import { auth } from "./Authentication/firebase";
import { updateEmail, reauthenticateWithCredential, EmailAuthProvider, updatePassword } from "firebase/auth";
import { Form, Button, Container, Alert } from 'react-bootstrap';
import { useAuth } from "./Authentication/AuthProvider";

const SettingsPage = () => {
  const { currentUser } = useAuth();
  const [email, setEmail] = useState(currentUser?.email || "");
  const [newEmail, setNewEmail] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  useEffect(() => {
    if (currentUser) setEmail(currentUser.email);
  }, [currentUser]);

  const handleUpdateEmail = async () => {
    setError("");
    setSuccess("");
    if (!auth.currentUser) return setError("المستخدم غير مسجل الدخول.");
    if (!newEmail || newEmail === email) return setError("يرجى إدخال بريد إلكتروني جديد مختلف عن الحالي.");
    
    try {
      const credential = EmailAuthProvider.credential(auth.currentUser.email, currentPassword);
      await reauthenticateWithCredential(auth.currentUser, credential);
      await updateEmail(auth.currentUser, newEmail);
      setEmail(newEmail);
      setSuccess("تم تحديث البريد الإلكتروني بنجاح!");
      setNewEmail("");
      setCurrentPassword("");
    } catch (err) {
      setError("خطأ: " + err.message);
    }
  };

  const handleUpdatePassword = async () => {
    setError("");
    setSuccess("");
    if (!auth.currentUser) return setError("المستخدم غير مسجل الدخول.");
    if (!newPassword) return setError("يرجى إدخال كلمة مرور جديدة.");
    
    try {
      const credential = EmailAuthProvider.credential(auth.currentUser.email, currentPassword);
      await reauthenticateWithCredential(auth.currentUser, credential);
      await updatePassword(auth.currentUser, newPassword);
      setSuccess("تم تحديث كلمة المرور بنجاح!");
      setNewPassword("");
      setCurrentPassword("");
    } catch (err) {
      setError("خطأ: " + err.message);
    }
  };

  return (
    <Container className="mt-5">
      <h2>الإعدادات</h2>
      {error && <Alert variant="danger">{error}</Alert>}
      {success && <Alert variant="success">{success}</Alert>}
      
      <Form>
        <Form.Group controlId="formEmail" className="mt-3">
          <Form.Label>البريد الإلكتروني الحالي</Form.Label>
          <Form.Control type="email" value={email} disabled />
        </Form.Group>
        <Form.Group controlId="formNewEmail" className="mt-3">
          <Form.Label>البريد الإلكتروني الجديد</Form.Label>
          <Form.Control type="email" placeholder="أدخل البريد الإلكتروني الجديد" value={newEmail} onChange={(e) => setNewEmail(e.target.value)} />
        </Form.Group>
        <Form.Group controlId="formCurrentPassword" className="mt-3">
          <Form.Label>كلمة المرور الحالية</Form.Label>
          <Form.Control type="password" placeholder="أدخل كلمة المرور الحالية" value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)} />
        </Form.Group>
        <Button variant="primary" className="mt-3" onClick={handleUpdateEmail}>حفظ تغييرات البريد الإلكتروني</Button>
      </Form>
      <hr />
      <Form>
        <Form.Group controlId="formNewPassword" className="mt-3">
          <Form.Label>كلمة المرور الجديدة</Form.Label>
          <Form.Control type="password" placeholder="أدخل كلمة المرور الجديدة" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
        </Form.Group>
        <Button variant="danger" className="mt-3" onClick={handleUpdatePassword}>تغيير كلمة المرور</Button>
      </Form>
    </Container>
  );
};

export default SettingsPage;